import { WorkspaceService } from '@/services/WorkspaceService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faCircleExclamation, faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Table,
  Title,
  Text,
  ActionIcon,
  Space,
  Tooltip,
  Switch,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission } from '@/utils/CommonUtility';
import { PlanService } from '@/services/PlanService';
import { usePlanStore } from '@/stores/usePlanStore';

export function MiscellaneousPage() {

  const [defaultWorkspace, setDefaultWorkspace] = useWorkspaceStore((state) => [
    state.defaultWorkspace,
    state.setDefaultWorkspace,
  ]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [plan, fetchPlan] = usePlanStore((state) => [state.plan, state.fetchPlan]);

  useDocumentTitle('Miscellaneous | ' + defaultWorkspace?.agency?.name);
  console.log(' defaultWorkspace ', defaultWorkspace);
  console.log(' plan ', plan);


  const handleChange = (payload: any) => {
    new WorkspaceService()
      .miscellaneous(defaultWorkspace?._id || '', payload)
      .then((response) => {
        if (response.data.status) {
          const { transfer_utm_parameters, append_original_url, powered_by } = response.data.data;
          const newWorkspace = {
            ...defaultWorkspace,
            transfer_utm_parameters,
            append_original_url,
            powered_by,
          };
          //@ts-ignore
          setDefaultWorkspace(newWorkspace);
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
  };
  return defaultPermissions && checkPermission(defaultPermissions, ['view_other_settings']) ? (
    <Box
      style={{
        backgroundColor: 'var(--mantine-color-white)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        height: '100%',
        flexGrow: 1,
      }}
      ml={16}
      mr={16}
    >
      <Grid p={16}>
        <Grid.Col span={5}>
          <></>
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex align={'center'}>
            <Title pt={2} order={5} mr={8}>
              Miscellaneous
            </Title>
          </Flex>
        </Grid.Col>
        <Grid.Col span={5}>
          <></>
        </Grid.Col>
      </Grid>
      <Divider color="gray.2" />
      <Table stickyHeader verticalSpacing={'md'}>
        <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
          <Table.Tr>
            <Table.Th>Permissions</Table.Th>
            {/* <Table.Th ta={'center'}>Actions</Table.Th> */}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>
              <Switch
                size="sm"
                label={'Append original link to the URL of the shortened link.'}
                checked={defaultWorkspace?.append_original_url}
                onChange={() => {
                  handleChange({
                    key: 'append_original_url',
                    value: !defaultWorkspace?.append_original_url,
                  });
                }}
              />
            </Table.Td>
          </Table.Tr>
          {!defaultWorkspace?.agency?.is_whitelabel_enabled && !plan?.display_name?.toLowerCase().includes('basic') && (
            <Table.Tr>
              <Table.Td>
                <Tooltip
                  label="This option is always enabled for Basic plans and cannot be changed. Upgrade your plan for more control."
                  disabled={!plan?.display_name?.toLowerCase().includes('basic')}
                >
                  <div>
                    <Switch
                      size="sm"
                      label={'Show "Powered by Replug" on your branded messages and Biolinks.'}
                      checked={plan?.display_name?.toLowerCase().includes('basic') ? true : defaultWorkspace?.powered_by}
                      onChange={() => {
                        if (!plan?.display_name?.toLowerCase().includes('basic')) {
                          handleChange({
                            key: 'powered_by',
                            value: !defaultWorkspace?.powered_by,
                          });
                        }
                      }}
                    />
                  </div>
                </Tooltip>
              </Table.Td>
            </Table.Tr>
          )}
          <Table.Tr>
            <Table.Td>
              <Switch
                size="sm"
                label={'Transfer UTM Parameters to the Destination URL.'}
                checked={defaultWorkspace?.transfer_utm_parameters}
                onChange={() => {
                  handleChange({
                    key: 'transfer_utm_parameters',
                    value: !defaultWorkspace?.transfer_utm_parameters,
                  });
                }}
              />
            </Table.Td>
          </Table.Tr>
          {/* <Table.Tr>
            <Table.Td>
              <Switch
                size="sm"
                label={'Show summary page as a fallback if destination URL has iframe issue. '}
              />
            </Table.Td>
          </Table.Tr> */}
        </Table.Tbody>
      </Table>
      <Divider color="gray.2" />
    </Box>
  ) : ('');
}
